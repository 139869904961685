import store from '@/store'
import router from '@/router'
import { foundryRequest } from '@/config/authConfig';
import { ref, getCurrentInstance } from '@vue/composition-api'
import { getVuetify } from '@core/utils'

export default function useAcademyDashboard() {

    const vm = getCurrentInstance().proxy
    const completedCourseStudentData = ref([])

    const flattenData = (data) => {
      return data.map((item) => {
        return {
          ...item,
          office: item.office || '',
          year: parseInt(item.completed_date.substring(0,4)),
          // month: `${dayjs(item.date).get('month')+1}-${this.monthMapping[dayjs(item.date).get('month')]}`,
          month: parseInt(item.completed_date.substring(5,7)),
          week: getWeek(new Date(item.completed_date)),
          day: parseInt(item.completed_date.substring(8,10))
        }
      });
    }
    const getWeek = (date) => {
      var janOne = new Date(date.getFullYear(), 0, 2);
      return Math.ceil((((date - janOne) / 86400000) + date.getDay() + 1) / 7);
    }

    
    const fetchAcademyAnalytics = async (params) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
          .dispatch('app-academy-dashboard/fetchAcademyAnalytics', {
            ...params
          })
          .then(response => {
            completedCourseStudentData.value = flattenData(response.data)
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 404) {
                completedCourseStudentData.value = []
            }
            if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
          })
    }

    return {
        completedCourseStudentData,


        fetchAcademyAnalytics
    }
  

}