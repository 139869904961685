<template>
    <v-row class="match-height">
        <v-col
        cols="12"
        md="12"
        >
            
            <pivot
                ref="pivot_table"
                :data="data"
                :fields="fields"
                :available-field-keys="availableFieldKeys"
                :row-field-keys="rowFieldKeys"
                :col-field-keys="colFieldKeys"
                :reducer="reducer"
                :default-show-settings="defaultShowSettings">
                    <template v-slot:value="{ value }">
                        <div >
                            {{ value}}
                        </div>
                    </template>
                    <template v-slot:student="{ value }">
                        {{ capitalize(value) }}
                    </template>

                    <template v-slot:computing>
                        <div class="position-absolute w-100 h-100 text-center" style="z-index: 1;">
                        <div class="position-sticky bg-white d-inline-block mt-5 p-3" style="top: 0;">
                            <svg aria-hidden="true" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner fa-fw fa-pulse"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path></svg>
                            Loading table values...
                        </div>
                        </div>
                    </template>
                    <template v-slot:monthHeader="{ value }">{{monthMapping[value] }}
                    </template>
                    <template v-slot:weekHeader="{ value }">
                        <div  v-if="value !== 0">
                        Week {{ number(value) }}
                        </div>
                        <div v-else></div>
                    </template>
                </pivot>

        </v-col>
    </v-row>

  
</template>

<script>

import { onUnmounted, ref, onMounted, getCurrentInstance, watch } from '@vue/composition-api'
import Pivot from '@/views/components/pivot-table/Pivot'

export default ({
    components: {
        Pivot
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        reducer: {
            type: Function,
            default: () => () =>{}
        },
        reduceAttr: {
            type: String,
            default: () => 'count'
        }
    },
    setup(props) {

        const monthMapping = ref({
                1: 'Jan',
                2: 'Feb',
                3: 'Mar',
                4: 'Apr',
                5: 'May',
                6: 'Jun',
                7: 'Jul',
                8: 'Aug',
                9: 'Sep',
                10: 'Oct',
                11: 'Nov',
                12: 'Dec'
            })

            const asyncData = ref([])
            const availableFieldKeys = ref(['course_name', 'category', 'completed_date', 'office', 'year', 'month', 'week'])
            const defaultShowSettings = ref(false)
            const isDataLoading = ref(false)

            //Project Params
            const fields = ref([{
                key: 'student',
                getter: item => item.student,
                label: 'Student',
                valueFilter: true
            },{
                key: 'level',
                getter: item => item.level,
                label: 'Level',
                valueFilter: true
            },{
                key: 'category',
                getter: item => item.category,
                label: 'Category',
                valueFilter: true
            },{
                key: 'completed_date',
                getter: item => item.completed_date,
                label: 'Completed',
                valueFilter: true,
            }, {
                key: 'office',
                getter: item => item.office,
                label: 'Office',
                valueFilter: true
            }, {
                key: 'course_name',
                getter: item => item.course_name,
                label: 'Course',
                valueFilter: true
            }, {
                key: 'year',
                getter: item => item.year,
                label: 'Year',
                valueFilter: true
            }, {
                key: 'month',
                getter: item => item.month,
                label: 'Month',
                sort: (a, b) => (a > b ? 1 : -1),
                headerSlotName: 'monthHeader',
                valueFilter: true,
                mapping: {
                    1: 'Jan',
                    2: 'Feb',
                    3: 'Mar',
                    4: 'Apr',
                    5: 'May',
                    6: 'Jun',
                    7: 'Jul',
                    8: 'Aug',
                    9: 'Sep',
                    10: 'Oct',
                    11: 'Nov',
                    12: 'Dec'
                }
            }, {
                key: 'week',
                getter: item => item.week,
                label: 'Week',
                headerSlotName: 'weekHeader',
                valueFilter: true
            }])
            const rowFieldKeys = ref(['student'])
            const colFieldKeys = ref(['level'])
            

            const capitalize = (value) => {
                return value.replace(/\b\w/g, l => l.toUpperCase())
            }


            const number = (value) => {
                return value.toLocaleString()
            }

            return {
                fields,
                monthMapping,
                asyncData,
                availableFieldKeys,
                defaultShowSettings,
                isDataLoading,
                rowFieldKeys,
                colFieldKeys,
                capitalize,
                number,
            }
        
    },
})
</script>

<style>

</style>